import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getActiveMarker,
  getCurrentLocationFloorplan,
  getSensorDetails,
  getSensorNames,
} from '../state/selectors';
import useGlobalStyles from '../styles/index';
import { SensorLatest } from '../services/api';
import DataStore from '../services/dataStore';
import { SensorHistoryPlotItem } from '../Widgets/Plots/plotCommon';
import ColouredLinePlot from '../Widgets/Plots/ColouredLinePlot';
import Loading from '../components/Loading';
import PositioningMap from '../Widgets/Map/PositioningMap';
import { setCurrentLocation } from '../state/actions';
import Header from '../components/Header';
import { getSensorDetailsById } from '../utils/sensors';

enum DisplayType {
  sensorInfo = 'sensorInfo',
  sensorMap = 'sensorMap',
  sensorHistory = 'sensorHistory',
}

function AppSensorInfo(): JSX.Element {
  const navigate = useNavigate();
  const globalClasses = useGlobalStyles();
  const dataStore = DataStore.getInstance();
  const dispatch = useDispatch();
  const { displayType, selectedSensorId } = useParams() as {
    displayType: DisplayType;
    selectedSensorId: string;
  };

  const activeMarker = useSelector(getActiveMarker);
  const allSensorNames = useSelector(getSensorNames);
  const allSensorDetails = useSelector(getSensorDetails);
  const floorPlan = useSelector(getCurrentLocationFloorplan);
  const [sensorDetails, setSensorDetails] = useState<SensorLatest>();
  const [sensorData, setSensorData] = useState<SensorHistoryPlotItem>();

  useEffect(() => {
    dataStore.getHistory(selectedSensorId, activeMarker).then((history) => {
      const dataItem: SensorHistoryPlotItem = {
        varName: activeMarker,
        sensorId: selectedSensorId,
        sensorName: sensorDetails?.name ?? '',
        history: {
          time: history.map(({ time }) => time),
          value: history.map(({ value }) => value),
        },
      };

      setSensorData(dataItem);
    });
  }, [activeMarker, dataStore, selectedSensorId, sensorDetails?.name]);

  useEffect(() => {
    if (!sensorDetails || sensorDetails.id !== selectedSensorId) {
      getSensorDetailsById(selectedSensorId, allSensorNames, allSensorDetails).then((sensor) => {
        if (sensor) {
          dispatch(setCurrentLocation(sensor?.location ?? '#'));
          setSensorDetails(sensor);
        }
      });
    }
  }, [selectedSensorId, allSensorNames, allSensorDetails, sensorDetails, dispatch]);

  useEffect(() => {
    if (displayType === DisplayType.sensorInfo && sensorDetails?.id) {
      navigate(`/sensors/${sensorDetails?.id}`);
    }
  }, [displayType, navigate, sensorDetails?.id]);

  const showSensorHistoryContent = () => {
    if (sensorData)
      return (
        <div className={globalClasses.sensorGraph}>
          <ColouredLinePlot data={sensorData} />
        </div>
      );
    return <Loading />;
  };

  return (
    <div className={globalClasses.bodyContent} style={{ padding: '10px' }}>
      <Header />
      {displayType === DisplayType.sensorHistory && showSensorHistoryContent()}
      {displayType === DisplayType.sensorMap && floorPlan && (
        <PositioningMap
          onChange={() => null}
          floorPlan={floorPlan}
          position={sensorDetails?.position}
          mapName=""
        />
      )}
    </div>
  );
}

export default AppSensorInfo;
