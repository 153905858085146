import dayjs from 'dayjs';
import { Datum } from 'plotly.js';
import { DailyMetricItem, SensorLatest } from '../../services/api';
import { themeProps } from '../../styles/theme';
import { getDataBandParams, motionUtilisationBands } from '../../utils/dataBandParams';
import { VarName } from '../../utils/varNames';
import { getSensorName } from '../../utils/sensors';
import { HoursSelection, SortType } from '../../state/types';
import { getDataValueNumber, getDataValueString } from '../HelperComponents/DataValueString';

export enum CalendarSelectionType {
  week = 'week',
  calendar = 'calendar',
}

export interface SensorValueItem {
  id: string;
  avg: number | null;
}

export interface HoveredPlot {
  x: string | number | Date | null;
  y: string | number | Date | null;
  z: string | number | null;
}

export enum ValueType {
  min = 'Minimum',
  avg = 'Average',
  max = 'Maximum',
  occ = 'Occupancy',
  utl = ' Utilisation',
}

export enum FloorplanSpace {
  room = 'room',
  floor = 'floor',
  desk = 'desk',
  area = 'area',
}

export enum PlotType {
  avg = 'Avg',
  min = 'Min',
  max = 'Max',
}

export interface SpaceProperty {
  label: string;
  code: string;
}

export const spaceProperties: Record<FloorplanSpace, SpaceProperty> = {
  room: {
    label: 'Room',
    code: 'area.room',
  },
  floor: {
    label: 'Floor',
    code: 'area.floor',
  },
  desk: {
    label: 'Desk',
    code: 'furniture.desk',
  },
  area: {
    label: 'Area',
    code: 'area',
  },
};

export const getHourSet = (selectHours: boolean, startHour: number, endHour: number) => {
  let startH = 0;
  let endH = 23;
  if (selectHours) {
    startH = startHour;
    endH = endHour - 1;
  }
  const allHours = [];
  for (let i = startH; i <= endH; i++) allHours.push(i);
  return allHours;
};

const getMinUtl = (metricData: DailyMetricItem) => {
  let minUtl;
  const { hours, utl } = metricData;
  if (hours) {
    for (let i = 0; i < hours.length; i++) {
      if (hours[i].utl !== undefined) {
        const percentUtl = Number((hours[i].utl ?? 0 * 100).toFixed(1));
        if (minUtl === undefined) minUtl = percentUtl;
        else if (minUtl !== undefined && percentUtl < minUtl) minUtl = percentUtl;
      }
    }
  } else if (utl !== undefined) {
    minUtl = Number((utl * 100).toFixed(1));
  }

  return minUtl;
};

const getMaxUtl = (metricData: DailyMetricItem) => {
  let maxUtl;
  const { hours, utl } = metricData;
  if (hours) {
    for (let i = 0; i < hours.length; i++) {
      if (hours[i]?.utl !== undefined) {
        const percentUtl = Number((hours[i]?.utl ?? 0 * 100).toFixed(1));
        if (maxUtl === undefined) maxUtl = percentUtl;
        else if (maxUtl !== undefined && percentUtl > maxUtl) maxUtl = percentUtl;
      }
    }
  } else if (utl) {
    maxUtl = Number((utl * 100).toFixed(1));
  }
  return maxUtl;
};

export const getMinDataMetricValue = (metricData: DailyMetricItem[], activeMarker: VarName) => {
  let minValue = null;
  for (let i = 0; i < metricData.length; i++) {
    const { tot, hours, min } = metricData[i];
    if (activeMarker === VarName.MotionEvent) {
      // for motion events, get min value from the UTL
      const utlValue = getMinUtl(metricData[i]);
      if (utlValue !== undefined) {
        if (minValue === null) minValue = utlValue;
        else if (utlValue < minValue) minValue = utlValue;
      }
    } else if (tot !== undefined) {
      // if has tot data, use it to find minValue
      let minTotValue = tot;
      const hourValue = hours;
      if (hourValue) {
        // find minimum Tot value, comparing from each hour data
        minTotValue =
          hours?.reduce((prev, curr) => ((prev?.tot ?? 0) < (curr?.tot ?? 0) ? prev : curr))?.tot ??
          0;
      }
      if (minTotValue !== undefined) {
        if (minValue === null) minValue = minTotValue;
        else if (minTotValue < minValue) minValue = minTotValue;
      }
    } else {
      // for all other cases use min value, derived from the api data
      const metricMinValue = min;
      if (metricMinValue !== undefined) {
        if (minValue === null) minValue = metricMinValue;
        else if (metricMinValue < minValue) minValue = metricMinValue;
      }
    }
  }
  return minValue;
};

export const getMaxDataMetricValue = (metricData: DailyMetricItem[], activeMarker: VarName) => {
  let maxValue = null;
  for (let i = 0; i < metricData.length; i++) {
    const { tot, hours, max } = metricData[i];
    if (activeMarker === VarName.MotionEvent) {
      const utlValue = getMaxUtl(metricData[i]);
      if (utlValue !== undefined) {
        if (maxValue === null) maxValue = utlValue;
        else if (utlValue > maxValue) maxValue = utlValue;
      }
    } else if (tot !== undefined) {
      let maxTotValue;
      const hourTotValues = [];
      if (hours) {
        // find maximum Tot value, comparing from each hour data
        for (let j = 0; j < hours.length; j++) {
          hourTotValues.push(hours[j]?.tot ?? 0);
        }
        maxTotValue = Math.max.apply(null, hourTotValues);
      } else maxTotValue = tot;
      if (maxTotValue !== undefined) {
        if (maxValue === null) maxValue = maxTotValue;
        else if (maxTotValue > maxValue) maxValue = maxTotValue;
      }
    } else if (max !== undefined) {
      if (maxValue === null) maxValue = max;
      else if (max > maxValue) maxValue = max;
    }
  }
  return maxValue;
};

export const getAvgDataMetricValue = (metricData: DailyMetricItem[], activeMarker: VarName) => {
  let metricDataLength = metricData.length;
  let totalValue: number | undefined;
  let avgValue = null;
  // sum up avg hourly or day data and calculate avgValue from metric data length
  for (let i = 0; i < metricData.length; i++) {
    const { utl, tot, hours, avg } = metricData[i];
    if (activeMarker === VarName.MotionEvent) {
      // use utl value for motion reading
      metricDataLength = metricData.filter((data) => data.utl !== undefined).length;
      if (utl !== undefined) {
        if (totalValue === undefined) totalValue = utl * 100;
        else totalValue += utl * 100;
      }
    } else if (tot !== undefined) {
      // if metricdata has tot value, use it to find avg
      let avgTot;
      const hourTotValues = [];
      if (hours) {
        // sum up hourly tot data and get avg tot value
        for (let j = 0; j < hours.length; j++) {
          hourTotValues.push(hours[j]?.tot ?? 0);
        }
        avgTot = hourTotValues.reduce((prev, curr) => prev + curr, 0) / hourTotValues.length ?? 0;
      } else avgTot = tot; // use day tot value for calendar plot
      if (avgTot !== undefined) {
        if (totalValue === undefined) totalValue = avgTot;
        else totalValue += avgTot;
      }
    } else {
      const datawithAvg = metricData.filter((data) => data.avg !== undefined);
      metricDataLength = datawithAvg.length;
      if (avg !== undefined) {
        if (totalValue === undefined) totalValue = avg;
        else totalValue += avg;
      }
    }
    if (totalValue !== undefined) {
      if (totalValue === 0 || metricDataLength === 0) avgValue = totalValue;
      else avgValue = Number((totalValue / metricDataLength).toFixed(1));
    }
  }
  return avgValue;
};

const getHourAvgValue = (hour: Datum, dataMetric: DailyMetricItem[], activeMarker: VarName) => {
  let avgValue;
  const avgData: number[] = [];
  for (let i = 0; i < dataMetric.length; i++) {
    const hourData = dataMetric[i].hours?.find((hourDataItem) => hourDataItem.h === hour);
    if (hourData) {
      const { utl, tot, avg } = hourData;
      if (activeMarker === VarName.MotionEvent) {
        if (utl !== undefined) avgData.push(utl * 100);
      } else if (tot !== undefined) avgData.push(tot);
      else if (avg !== undefined) avgData.push(avg);
    }
  }
  if (avgData.length > 0) {
    avgValue = Number((avgData.reduce((a, b) => a + b, 0) / avgData.length).toFixed(1));
  } else avgValue = null;
  return avgValue;
};

const getDayAvgValue = (dataMetric: DailyMetricItem[], activeMarker: VarName) => {
  let avgValue;
  const avgData: number[] = [];
  for (let i = 0; i < dataMetric.length; i++) {
    const { tot, utl, avg } = dataMetric[i];
    if (activeMarker === VarName.MotionEvent) {
      if (utl !== undefined) avgData.push(utl * 100);
    } else if (tot !== undefined) avgData.push(tot);
    else if (avg !== undefined) avgData.push(avg);
  }
  if (avgData.length > 0) {
    avgValue = Number((avgData.reduce((a, b) => a + b, 0) / avgData.length).toFixed(1));
  } else avgValue = null;
  return avgValue;
};

const getWeekHoveredValue = (
  hoveredPlot: HoveredPlot,
  sensorData: DailyMetricItem[],
  activeMarker: VarName
) => {
  let hoveredValue: number | null = null;
  const selectedHour = hoveredPlot.x as number;
  const dayNum = dayjs(hoveredPlot.y).get('day');
  const dataMetric = sensorData.filter((data) => dayjs(data.date).get('day') === dayNum);
  if (hoveredPlot.y === PlotType.avg) {
    if (hoveredPlot.x === PlotType.min) {
      hoveredValue = getMinDataMetricValue(sensorData, activeMarker);
    } else if (hoveredPlot.x === PlotType.max) {
      hoveredValue = getMaxDataMetricValue(sensorData, activeMarker);
    } else if (hoveredPlot.x === PlotType.avg) {
      hoveredValue = getAvgDataMetricValue(sensorData, activeMarker);
    } else hoveredValue = getHourAvgValue(selectedHour, sensorData, activeMarker);
  } else if (hoveredPlot.x === PlotType.min) {
    hoveredValue = getMinDataMetricValue(dataMetric, activeMarker);
  } else if (hoveredPlot.x === PlotType.avg) {
    hoveredValue = getAvgDataMetricValue(dataMetric, activeMarker);
  } else if (hoveredPlot.x === PlotType.max) {
    hoveredValue = getMaxDataMetricValue(dataMetric, activeMarker);
  } else hoveredValue = getHourAvgValue(selectedHour, dataMetric, activeMarker);
  return hoveredValue;
};

const getCalendarHoveredValue = (
  hoveredPlot: HoveredPlot,
  sensorData: DailyMetricItem[],
  activeMarker: VarName
) => {
  let hoveredValue: number | null | undefined = null;
  const dayNum = dayjs(hoveredPlot.y).get('day');
  const dateFormatX = dayjs(hoveredPlot.x).day(dayNum).format('YYYY-MM-DD');
  const dataMetric = sensorData.filter((data) => dayjs(data.date).get('day') === dayNum);
  if (hoveredPlot.y === PlotType.avg) {
    if (hoveredPlot.x === PlotType.min) {
      hoveredValue = getMinDataMetricValue(sensorData, activeMarker);
    } else if (hoveredPlot.x === PlotType.max) {
      hoveredValue = getMaxDataMetricValue(sensorData, activeMarker);
    } else if (hoveredPlot.x === PlotType.avg) {
      hoveredValue = getAvgDataMetricValue(sensorData, activeMarker);
    } else {
      const startOfWeek = hoveredPlot.x;
      const endOfWeek = dayjs(startOfWeek).endOf('week').format('YYYY-MM-DD');
      const historyWeekData = sensorData.filter(
        (data) => dayjs(data.date) >= dayjs(startOfWeek) && dayjs(data.date) <= dayjs(endOfWeek)
      );
      hoveredValue = getDayAvgValue(historyWeekData, activeMarker);
    }
  } else if (hoveredPlot.x === PlotType.min) {
    hoveredValue = getMinDataMetricValue(dataMetric, activeMarker);
  } else if (hoveredPlot.x === PlotType.avg) {
    hoveredValue = getAvgDataMetricValue(dataMetric, activeMarker);
  } else if (hoveredPlot.x === PlotType.max) {
    hoveredValue = getMaxDataMetricValue(dataMetric, activeMarker);
  } else {
    const dayMetric = sensorData.find((data) => data.date === dateFormatX);
    if (dayMetric) {
      const { utl, avg } = dayMetric;
      if (activeMarker === VarName.MotionEvent) {
        if (utl !== undefined) hoveredValue = Number((utl * 100).toFixed(1));
      } else if (avg !== undefined) hoveredValue = avg;
    }
  }
  return hoveredValue;
};

const getAvgHoveredBox = (
  hoveredPlot: HoveredPlot,
  sensorData: DailyMetricItem[],
  activeMarker: VarName
) => {
  let hoveredAvgValue;
  const hasHourValues = sensorData.find((data) => data.hours !== undefined);
  if (hasHourValues) hoveredAvgValue = getWeekHoveredValue(hoveredPlot, sensorData, activeMarker);
  else hoveredAvgValue = getCalendarHoveredValue(hoveredPlot, sensorData, activeMarker);
  return hoveredAvgValue;
};

export const getAvgValue = (
  locHistoryData: DailyMetricItem[],
  sensorId: string,
  activeMarker: VarName,
  selectedHours: HoursSelection,
  hoveredPlot?: HoveredPlot
) => {
  let average;
  const { selectHours, includeWeekends } = selectedHours;
  const sensorData = locHistoryData.filter((data) => data.id === sensorId);
  const hasTotValue =
    locHistoryData.filter((data) => Object.prototype.hasOwnProperty.call(data, 'tot')).length > 0;
  if (hoveredPlot) {
    average = getAvgHoveredBox(hoveredPlot, sensorData, activeMarker);
  } else {
    let total = null;
    let points = null;
    let comparingData = sensorData;
    if (selectHours && !includeWeekends)
      comparingData = sensorData.filter(
        (dataItem) => dayjs(dataItem.date).day() !== 6 && dayjs(dataItem.date).day() !== 0
      );
    for (let i = 0; i < comparingData.length; i++) {
      let avgData;
      const { utl, tot, avg } = comparingData[i];
      if (activeMarker === VarName.MotionEvent) {
        if (utl !== undefined) avgData = utl * 100;
      } else if (hasTotValue && tot !== undefined) avgData = tot;
      else if (avg !== undefined) avgData = avg;
      if (avgData !== undefined) {
        if (!total) total = avgData;
        else total += avgData;
        if (!points) points = 1;
        else points += 1;
      }
    }
    average = hasTotValue ? total : total && points && Number((total / points).toFixed(1));
  }

  return average;
};

const getVarNameData = (sensor: SensorLatest, varName: VarName) =>
  sensor?.data?.find((data) => data.varName === varName);

export const getVarNameValue = (
  threshold: [number, number],
  sensor: SensorLatest,
  varName: VarName
) => {
  const data = getVarNameData(sensor, varName);
  let value;
  if (data && data.time && data.value) {
    value = getDataValueNumber(data, threshold);
  }
  return value;
};

export const getMotionUtlBandParams = (value: number) => {
  const bandParams = motionUtilisationBands
    .filter((params) => value <= params.upperBound)
    .sort((paramsA, paramsB) => paramsA.upperBound - paramsB.upperBound)[0];
  return bandParams;
};

export const getDataString = (value: number | null | undefined, varName: VarName) => {
  let dataValueString = '';
  // eslint-disable-next-line use-isnan
  if (value !== null && value !== undefined) {
    if (varName === VarName.MotionEvent) {
      const valueString = value.toString();
      dataValueString = `${valueString} %`;
    } else dataValueString = getDataValueString(value, varName, undefined, undefined, false);
  }
  return dataValueString;
};

export const getBandColor = (value: number | null | undefined, varName: VarName) => {
  let color = themeProps.colors.grey;
  // eslint-disable-next-line use-isnan
  if (value !== null && value !== undefined) {
    if (varName === VarName.MotionEvent) {
      color = getMotionUtlBandParams(value).color;
    } else color = getDataBandParams(varName, value).color;
  }
  return color;
};

export const sortSensors = (
  sensors: SensorLatest[],
  sortBy: SortType,
  sensorSummaryValues: SensorValueItem[],
  sensorNames: Map<string, string>
) => {
  let sortedSensors = sensors;
  for (let i = 0; i < sensors.length; i++) {
    if (sortBy.property === 'name') {
      sortedSensors = sensors.sort((a, b) => {
        const nameA = getSensorName(sensorNames, a.id);
        const nameB = getSensorName(sensorNames, b.id);
        if (nameA > nameB && sortBy.ascending) {
          return -1;
        }
        if (nameA < nameB && sortBy.ascending) {
          return 1;
        }
        if (nameA > nameB && !sortBy.ascending) {
          return 1;
        }
        if (nameA < nameB && !sortBy.ascending) {
          return -1;
        }
        return 0;
      });
    } else if (sortBy.property === 'value') {
      sortedSensors = sensors.sort((a, b) => {
        const avgValueA = sensorSummaryValues.find((data) => data.id === a.id)?.avg ?? 0;
        const avgValueB = sensorSummaryValues.find((data) => data.id === b.id)?.avg ?? 0;
        if (avgValueA !== undefined && avgValueB !== undefined) {
          if (avgValueA > avgValueB && sortBy.ascending) {
            return 1;
          }
          if (avgValueA < avgValueB && sortBy.ascending) {
            return -1;
          }
          if (avgValueA > avgValueB && !sortBy.ascending) {
            return -1;
          }
          if (avgValueA < avgValueB && !sortBy.ascending) {
            return 1;
          }
        }
        return 0;
      });
    }
  }
  return sortedSensors;
};

export const getPrevTimeRange = (
  startDate: Date,
  endDate: Date,
  locHistoryData: DailyMetricItem[]
) => {
  const hasHourValues = locHistoryData.find((data) => data.hours !== undefined);
  let prevStartDate = startDate;
  let prevEndDate = endDate;
  if (hasHourValues) {
    if (dayjs().diff(dayjs(startDate), 'days') === 6) {
      prevStartDate = dayjs().startOf('week').week(dayjs().week()).toDate();
      prevEndDate = dayjs().endOf('week').week(dayjs().week()).toDate();
    } else {
      prevStartDate = dayjs(startDate).subtract(7, 'days').toDate();
      prevEndDate = dayjs(endDate).subtract(7, 'days').toDate();
    }
  } else if (dayjs().diff(dayjs(startDate), 'days') === 364) {
    prevStartDate = dayjs().startOf('year').year(dayjs().year()).toDate();
    prevEndDate = dayjs().endOf('year').year(dayjs().year()).toDate();
  } else {
    prevStartDate = dayjs()
      .startOf('year')
      .year(dayjs(startDate).year() - 1)
      .toDate();
    prevEndDate = dayjs()
      .endOf('year')
      .year(dayjs(startDate).year() - 1)
      .toDate();
  }
  return { prevStart: prevStartDate, prevEnd: prevEndDate };
};

export const getNextTimeRange = (
  startDate: Date,
  endDate: Date,
  locHistoryData: DailyMetricItem[]
) => {
  let nextStartDate;
  let nextEndDate;
  const hasHourValues = locHistoryData.find((data) => data.hours !== undefined);
  if (hasHourValues) {
    if (
      dayjs(endDate).format('YYYY-MM-DD') ===
      dayjs().endOf('week').week(dayjs().week()).format('YYYY-MM-DD')
    ) {
      nextStartDate = dayjs().subtract(6, 'days').startOf('day').toDate();
      nextEndDate = dayjs().endOf('day').toDate();
    } else {
      nextStartDate = dayjs(startDate).add(7, 'days').toDate();
      nextEndDate = dayjs(endDate).add(7, 'days').toDate();
    }
  } else if (
    dayjs(endDate).format('YYYY-MM-DD') ===
    dayjs().endOf('year').year(dayjs().year()).format('YYYY-MM-DD')
  ) {
    nextStartDate = dayjs().subtract(364, 'days').startOf('day').toDate();
    nextEndDate = dayjs().endOf('day').toDate();
  } else {
    nextStartDate = dayjs()
      .startOf('year')
      .year(dayjs(startDate).year() + 1)
      .toDate();
    nextEndDate = dayjs()
      .endOf('year')
      .year(dayjs(endDate).year() + 1)
      .toDate();
  }
  return { nextStart: nextStartDate, nextEnd: nextEndDate };
};

// update min/max/avg values based on selected hours and return new metric items
export const processRawData = (
  rawData: DailyMetricItem[],
  selectedHours: number[],
  hourly?: boolean,
  offsetWeeks?: number // for ghost comparison data
): DailyMetricItem[] => {
  let offsetData = rawData;
  if (offsetWeeks) {
    const offsetDays = offsetWeeks * 7;
    offsetData = rawData.map(({ date, ...rest }) => {
      if (date !== undefined) {
        const newDate = new Date(date);
        // Data is from offsetWeeks before so need to move forward to match comparison data
        newDate.setDate(newDate.getDate() + offsetDays);
        return {
          ...rest,
          date: newDate.toISOString().split('T')[0],
        }; // Update the date in ISO format
      }
      return { date, ...rest };
    });
  }
  // Create custom `DailyMetricItem`s where the `date`s are specific hours
  if (hourly) {
    const outputArray = offsetData.flatMap(
      (item) =>
        item.hours?.map(({ h, ...props }) => ({
          id: item.id,
          date: `${item.date} ${String(h).padStart(2, '0')}:00:00`,
          ...props,
        })) || [] // Return an empty array if hours is undefined
    );
    return outputArray;
  }
  const processedData: DailyMetricItem[] = [];
  offsetData.forEach((metricItem) => {
    const processedMetricItem: DailyMetricItem = { ...metricItem };
    const { avg, min, max, utl, tot, hours } = processedMetricItem;
    const hoursItem = hours?.filter((item) => selectedHours.includes(item.h)) ?? [];
    const totalPts = hoursItem.reduce((total, next) => total + (next.pts as number), 0);
    processedMetricItem.hours = hoursItem;
    processedMetricItem.pts = totalPts;

    if (min !== undefined)
      processedMetricItem.min = Math.min(...hoursItem.map((item) => item.min as number));

    if (max !== undefined)
      processedMetricItem.max = Math.max(...hoursItem.map((item) => item.max as number));

    if (utl !== undefined)
      processedMetricItem.utl =
        hoursItem.reduce((total, next) => total + (next.utl as number), 0) / hoursItem.length;

    if (tot !== undefined)
      processedMetricItem.tot = hoursItem.reduce((total, next) => total + (next.tot as number), 0);

    if (avg !== undefined)
      processedMetricItem.avg =
        hoursItem.reduce((total, next) => total + (next.avg as number) * (next.pts as number), 0) /
        totalPts;

    processedData.push(processedMetricItem);
  });
  return processedData;
};
