import React, { useState, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import { VarName, varNameDetails } from '../../utils/varNames';
import useStyles from '../../styles';
import BaseArrayRow from './BaseArrayRow';
import BaseArraySummary from './BaseArraySummary';
import { DashSummary, BandValues, gradientGenerator, majorQualityCalculation } from './base';
import { getSensorName } from '../../utils/sensors';
import { getSensorNames, getDashboardPanels } from '../../state/selectors';
import {
  BandParamsType,
  getDataBandParams,
  varNameBandParams,
} from '../../utils/dataBandParams';
import DataValueString from '../HelperComponents/DataValueString';
import { DataTreeItem } from '../../services/api';
import Help from '../../components/Help';
import useDataFiltering from './useDataFiltering';
import LocationIdentifier from './LocationIdentifier';
import { getShortTimeStr } from '../../utils/functions';

function BandSensor({ data, varName, firstItem, children }: DashSummary): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const sensorNames = useSelector(getSensorNames);
  const [currentFilter, setCurrentFilter] = useState<BandParamsType[]>([]);
  const openPanels = useSelector(getDashboardPanels);
  const expanded = openPanels.indexOf(varName) !== -1;
  const dataToList = useDataFiltering(data);

  const bandDetails: BandParamsType[] = useMemo(
    () => varNameBandParams[varName] as BandParamsType[],
    [varName]
  );

  const perBandSums: BandValues[] = useMemo(() => {
    const result: BandValues[] = bandDetails.map(({ color }) => ({
      total: 0,
      pct: 0,
      colour: color,
    }));

    data.forEach(({ value }: DataTreeItem) => {
      for (let i = 0; i < result.length; i++) {
        if ((value ?? Infinity) <= bandDetails[i].upperBound) {
          result[i].total += 1;
          break;
        }
      }
    });

    result.forEach((item, index) => {
      result[index].pct = (100 * item.total) / data.length;
    });

    return result;
  }, [data, bandDetails]);

  const majorQuality = useMemo(() => {
    const f = majorQualityCalculation[varName];
    if (!f) {
      return undefined;
    }

    return f(perBandSums, bandDetails);
  }, [perBandSums, bandDetails, varName]);

  const toggleFilter = (band: BandParamsType) => {
    if (currentFilter) {
      const index = currentFilter?.indexOf(band);

      if (index === -1) {
        currentFilter?.push(band);
      } else {
        currentFilter.splice(index, 1);
      }

      setCurrentFilter([...currentFilter]);
    }
  };

  return (
    <div>
      <BaseArraySummary
        details={varNameDetails[varName]}
        expanding
        dataAvailable={data.length > 0}
        firstItem={firstItem}
      >
        {!expanded && majorQuality && (
          <Tooltip title={majorQuality.expandedText} placement="top">
            <span
              className={`${classes.pillLabel} ${classes.gradientBar}`}
              style={{
                background: gradientGenerator(perBandSums),
                borderColor: 'transparent',
              }}
            >
              {majorQuality.pct.toFixed(0)}% {majorQuality.label}
              {firstItem && (
                <Help
                  helpText="Hover over the coloured bar for more informaton"
                  position="bottom"
                />
              )}
            </span>
          </Tooltip>
        )}
        {(expanded || !majorQuality) && (
          <div
            style={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            {perBandSums.map((band, index) => {
              const bd = bandDetails[index];
              const filterActive = currentFilter.length === 0 || currentFilter.indexOf(bd) !== -1;

              const { description, text, label, color, lightBackground } = bd;
              return (
                <Tooltip
                  title={description ?? text}
                  placement="top"
                  key={`sensorarray-${varName}-extended-${label}`}
                >
                  <button
                    type="button"
                    onClick={() => toggleFilter(bd)}
                    key={`sensorarray-${varName}-summary-${label}`}
                    className={`${classes.pillLabel} ${classes.interactivePill}`}
                    style={{
                      background: filterActive ? color : 'transparent',
                      borderColor:
                        lightBackground || !filterActive ? theme.palette.text.primary : color,
                      color: lightBackground || !filterActive ? theme.palette.text.primary : '#fff',
                    }}
                  >
                    {band.total} {label}
                  </button>
                </Tooltip>
              );
            })}
          </div>
        )}
      </BaseArraySummary>
      {expanded && children && (
        <div className={classes.sensorArraySensorSummaryOptionalRow}>{children}</div>
      )}
      {expanded && data.length > 0 && (
        <List className={classes.sensorArraySensorList}>
          {dataToList
            .filter(
              ({ value }) =>
                currentFilter.length === 0 ||
                currentFilter.indexOf(getDataBandParams(varName, value ?? Infinity)) !== -1
            )
            .slice(0, 49) // Limit to first 50 sensors to avoid slow rendering
            .map(({ id, time, value, location, position }) => {
              const bandParams = getDataBandParams(varName, value ?? Infinity) ?? {
                upperBound: undefined,
                color: '#000',
                text: '',
                label: '',
                description: '',
                lightBackground: false,
                desired: false,
              };

              return (
                <BaseArrayRow
                  id={id}
                  key={`sensorarray-${varName}-${id}`}
                  position={position}
                  varName={varName}
                >
                  <Tooltip
                    title={
                      <span>
                        {bandParams.text}
                        <br />
                        {bandParams.description}
                      </span>
                    }
                    placement="top"
                  >
                    <span
                      className={classes.pillLabel}
                      style={{
                        background: bandParams.color,
                        borderColor: bandParams.lightBackground ? 'black' : bandParams.color,
                        color: bandParams.lightBackground ? 'black' : 'white',
                      }}
                    >
                      {value !== undefined && <DataValueString value={value} varName={varName} showMetric={false} time={time} isRawValue={false}/>}
                      <span className={classes.pillUnitLabel}>
                        {varNameDetails[varName].metric}
                      </span>
                    </span>
                  </Tooltip>
                  <span>{time && getShortTimeStr(time)}</span>
                  <span>{getSensorName(sensorNames, id)}</span>
                  {location && <LocationIdentifier location={location} />}
                </BaseArrayRow>
              );
            })}
        </List>
      )}
    </div>
  );
}

BandSensor.defaultProps = {
  varName: VarName.Unknown,
};

export default BandSensor;
