/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import AddchartIcon from '@mui/icons-material/Addchart';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SensorLatest } from '../../services/api';
import {
  getActiveMarker,
  getHighlightedItem,
  getSelectedSensorIds,
  getSensorNames,
  getSortBy,
} from '../../state/selectors';
import { getBandColor, getDataString, sortSensors, SensorValueItem } from './helpers';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/calendarView';
import { setHighlightedItem, setSelectedSensors, setSortBy } from '../../state/actions';
import CalendarViewBaseSummary from './CalendarViewBaseSummary';
import { varNameDetails } from '../../utils/varNames';
import { SortType } from '../../state/types';

interface CalendarViewSensorArrayProps {
  sensors: SensorLatest[];
  title: string;
  sensorSummaryValues: SensorValueItem[];
}

function CalendarViewSensorArray({
  sensors,
  title,
  sensorSummaryValues,
}: CalendarViewSensorArrayProps): JSX.Element {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const sortBy = useSelector(getSortBy);
  const highlightedItem = useSelector(getHighlightedItem);
  const activeMarker = useSelector(getActiveMarker);
  const sensorNames = useSelector(getSensorNames);
  const selectedSensorIds = useSelector(getSelectedSensorIds);

  useEffect(() => {
    const defaultSort: SortType = { property: 'name', ascending: true };
    if (sortBy !== defaultSort) dispatch(setSortBy(defaultSort));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedSensors = useMemo(
    () => sortSensors(sensors, sortBy, sensorSummaryValues, sensorNames),
    [sensors, sortBy, sensorSummaryValues, sensorNames]
  );

  const mouseEnter = (id: string) => {
    dispatch(setHighlightedItem({ id, varName: activeMarker, source: 'dashboardPanel' }));
  };

  const mouseLeave = () => {
    dispatch(setHighlightedItem({ id: '' }));
  };

  const selectSensorHandler = (id: string) => {
    const sensorsList = [...selectedSensorIds];
    const isSelectedSensor = sensorsList.some((sensor) => sensor === id);
    if (isSelectedSensor)
      dispatch(setSelectedSensors(sensorsList.filter((sensor) => sensor !== id)));
    else {
      sensorsList.push(id);
      dispatch(setSelectedSensors(sensorsList));
    }
  };

  const filteredSummaryValues = useMemo(
    () =>
      sensorSummaryValues.filter((sensor) =>
        sortedSensors.map((sensorInfo) => sensorInfo.id).includes(sensor.id)
      ),
    [sensorSummaryValues, sortedSensors]
  );

  return (
    <Accordion style={{ width: '100%' }} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="h6"
          style={{
            textAlign: 'left',
            alignSelf: 'center',
          }}
        >
          {title}:
        </Typography>

        <CalendarViewBaseSummary sensorSummaryValues={filteredSummaryValues} expanded={expanded} />
      </AccordionSummary>
      <AccordionDetails>
        <ul style={{ width: '100%', padding: 0 }}>
          {sortedSensors.length > 0 ? (
            sortedSensors?.map((sensor) => {
              const avgValue = sensorSummaryValues.find((data) => data.id === sensor.id)?.avg;
              const dataValueString = getDataString(avgValue, activeMarker);
              const color = getBandColor(avgValue, activeMarker);
              const isSelectedSensor = selectedSensorIds.some((id) => id === sensor.id);
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={sensor.id}
                  className={`${globalClasses.sensorArraySensorListItem} ${
                    highlightedItem.id === sensor.id && globalClasses.highlightedItem
                  }`}
                  onMouseEnter={() => mouseEnter(sensor.id)}
                  onMouseLeave={mouseLeave}
                  onFocus={() => mouseEnter(sensor.id)}
                  onBlur={mouseLeave}
                >
                  <span
                    className={`${globalClasses.pillLabel} ${classes.occupancyPill}`}
                    style={{ background: color }}
                  >
                    {dataValueString || 'N/A'}
                    <span className={globalClasses.pillUnitLabel}>
                      {dataValueString ? varNameDetails[activeMarker].metric : ''}
                    </span>
                  </span>
                  <Typography variant="body1">{sensor.name}</Typography>
                  <div style={{ flex: '1 1 auto', width: '1px' }} />
                  <IconButton onClick={() => navigate(`/sensors/${sensor.id}`)}>
                    <Tooltip title="Sensor Info">
                      <InfoIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => selectSensorHandler(sensor.id)}
                    className={isSelectedSensor ? classes.lockedIcon : ''}
                  >
                    <Tooltip title="Add to selected sensors">
                      {isSelectedSensor ? <AssignmentTurnedInIcon /> : <AddchartIcon />}
                    </Tooltip>
                  </IconButton>
                </li>
              );
            })
          ) : (
            <Typography variant="body1">No sensor data found</Typography>
          )}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}

export default CalendarViewSensorArray;
