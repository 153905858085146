import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import RoomIcon from '@mui/icons-material/Room';
import { getSensorName } from '../../utils/sensors';
import { SensorLatest, VisibleSubSensor } from '../../services/api';
import { getBleLocSwitchStatus, getSensorNames } from '../../state/selectors';
import useGlobalStyles from '../../styles/index';
import { GatewayVisibleSubsensors } from '../SensorConfig/helpers';
import InstallProcess from './InstallProcess';
import { isDataExpired } from '../../utils/functions';
import SensorIcon from '../../styles/icons/SensorIcon';
import { sensorTypeDetails } from '../../utils/sensorProperties';
import LocateContainer from './LocateContainer';
import { setBleLocSwitchStatus } from '../../state/actions';

export enum InstallSteps {
  Locate = 'Locate',
  Position = 'Position',
  Name = 'Name',
  Confirm = 'Confirm',
}

export const steps = [
  InstallSteps.Locate,
  InstallSteps.Position,
  InstallSteps.Name,
  InstallSteps.Confirm,
];

interface InstallContainerProps {
  selectedSensorId: string;
  handleRefresh: () => void;
  gatewayVisibleSubsensors: GatewayVisibleSubsensors[] | undefined;
}

function InstallContainer({
  selectedSensorId,
  handleRefresh,
  gatewayVisibleSubsensors,
}: InstallContainerProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allSensorNames = useSelector(getSensorNames);
  const globalClasses = useGlobalStyles();
  const allowAutoLocSwitch = useSelector(getBleLocSwitchStatus);

  const [gatewayDetails, setGatewayDetails] = useState<SensorLatest>();
  const [selectedVisibleSubsensor, setSelectedVisibleSubsensor] = useState<VisibleSubSensor>();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const sensorName = getSensorName(allSensorNames, selectedSensorId);
    // only navigate on landing step, otherwise sensor will have name in the final step after confirmation
    if (sensorName !== selectedSensorId && activeStep === 0) {
      navigate(`/sensors/${selectedSensorId}`);
    }
  }, [activeStep, allSensorNames, navigate, selectedSensorId]);

  useEffect(() => {
    setSelectedVisibleSubsensor(undefined);
    const visibleGatewaySubsensor = gatewayVisibleSubsensors?.find(
      (gatewaySensors) => gatewaySensors.gatewayId === gatewayDetails?.id
    );
    const selectedSubsensor = visibleGatewaySubsensor?.visibleSubsensors.find(
      (sensor) => sensor.suggested_id === selectedSensorId
    );

    if (selectedSubsensor && !isDataExpired(selectedSubsensor.last_seen))
      setSelectedVisibleSubsensor(selectedSubsensor);
  }, [gatewayDetails, gatewayVisibleSubsensors, selectedSensorId]);

  const Icon = selectedVisibleSubsensor
    ? sensorTypeDetails[selectedVisibleSubsensor.type]?.icon ?? SensorIcon
    : SensorIcon;

  return (
    <Box sx={{ marginTop: '10px' }}>
      <Typography
        variant="h6"
        sx={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Icon />
        <Box sx={{ marginLeft: '10px' }}>Installing {selectedSensorId}</Box>
      </Typography>
      <Typography variant="body1" style={{ textAlign: 'center' }}>
        {selectedVisibleSubsensor?.type
          ? `${sensorTypeDetails[selectedVisibleSubsensor.type].label} -
          ${sensorTypeDetails[selectedVisibleSubsensor.type].brandName}`
          : 'Not yet visible in this location'}
      </Typography>

      <Stepper
        activeStep={activeStep}
        className={globalClasses.provisioningFormStepper}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel style={{ fontSize: '10px' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps[activeStep] === InstallSteps.Locate ? (
        <>
          {/* Select Location and gateway */}
          <Zoom in={steps[activeStep] === InstallSteps.Locate}>
            <Box>
              <LocateContainer
                selectedSensorId={selectedSensorId}
                gatewayDetails={gatewayDetails}
                setGatewayDetails={setGatewayDetails}
                gatewayVisibleSubsensors={gatewayVisibleSubsensors}
                handleNext={() => setActiveStep(activeStep + 1)}
              />
            </Box>
          </Zoom>
          {/* Fab icons are only displayed during pre install process i.e. selecting location and gateway */}
          <Fab
            color="warning"
            size="medium"
            className={`${globalClasses.floatingBtn}`}
            style={{ right: '10px', bottom: '20px' }}
          >
            <RefreshIcon
              onClick={() => {
                setGatewayDetails(undefined);
                handleRefresh();
              }}
            />
          </Fab>
          {!allowAutoLocSwitch && (
            <Fab
              color="error"
              size="medium"
              className={`${globalClasses.floatingBtn}`}
              style={{ left: '10px', bottom: '20px' }}
            >
              <RoomIcon onClick={() => dispatch(setBleLocSwitchStatus(true))} />
            </Fab>
          )}
        </>
      ) : (
        // Collect sensor position, name and submit with details
        <InstallProcess
          gatewayDetails={gatewayDetails as SensorLatest}
          selectedSubSensor={selectedVisibleSubsensor as VisibleSubSensor}
          handleRefresh={handleRefresh}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      )}
    </Box>
  );
}

export default InstallContainer;
