import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Zoom from '@mui/material/Zoom';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
  PropSensorItemCreate,
  SensorLatest,
  VisibleSubSensor,
  GeoJSON,
  SubSensorType,
} from '../../services/api';
import { PropSensorItemCreateIndexed } from '../../utils/sensorProperties';
import {
  getAllLocationFloorplan,
  getBleSensors,
  getCurrentLocationChildSensors,
  getSensorNames,
} from '../../state/selectors';
import { addNewSensor, fetchLocationFloorplan, fetchSensorLatest } from '../../services/apiService';
import PositioningMap from '../Map/PositioningMap';
import { SensorPositionData } from '../Map/types';
import Loading from '../../components/Loading';
import { setSensorsById, setUserPosition } from '../../state/actions';
import { getBleSensorTargetPosition, getSensorName } from '../../utils/sensors';
import { LocationStateProps, defaultLocation } from '../Map/mapHelpers';
import { themeProps } from '../../styles/theme';
import InstallSuccessIcon from '../../styles/icons/InstallSuccessIcon';
import { appSwitchBtnHandler } from '../../Shell/helpers';
import { InstallSteps, steps } from './InstallContainer';

interface InstallProcessProps {
  gatewayDetails: SensorLatest;
  selectedSubSensor: VisibleSubSensor;
  handleRefresh: () => void;
  activeStep: number;
  setActiveStep: (value: number) => void;
}

function InstallProcess({
  gatewayDetails,
  selectedSubSensor,
  handleRefresh,
  activeStep,
  setActiveStep,
}: InstallProcessProps): JSX.Element {
  const dispatch = useDispatch();
  const allFloorPlan = useSelector(getAllLocationFloorplan);
  const allSensorNames = useSelector(getSensorNames);
  const locSensors = useSelector(getCurrentLocationChildSensors);
  const bleSensors = useSelector(getBleSensors);

  const [floorPlan, setFloorPlan] = useState<GeoJSON>();
  const [sensorPayload, setSensorPayload] = useState<PropSensorItemCreateIndexed>();
  const [alertMsg, setAlertMsg] = useState('');
  const [creatingSensor, setCreatingSensor] = useState(false);
  const [fetchingInitialData, setFetchingInitialData] = useState<boolean>(true);
  const [position, setPosition] = useState<SensorPositionData>();
  const [mapLocationState, setMapLocationState] = useState<LocationStateProps>();

  // calculates view state based on all nearby detected sensors
  useEffect(() => {
    getBleSensorTargetPosition(bleSensors, locSensors, true).then((sensorPosition) => {
      if (sensorPosition) {
        const newViewState = {
          ...defaultLocation,
          latitude: sensorPosition?.lat ?? 53,
          longitude: sensorPosition?.lng ?? -1,
          zoom: sensorPosition?.lat && sensorPosition?.lng ? 21 : 4,
        };
        setMapLocationState(newViewState);
        dispatch(setUserPosition(sensorPosition));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locSensors]);

  const getFloorPlan = useCallback(() => {
    if (gatewayDetails?.location) {
      const currentLocationFloorPlan = allFloorPlan.get(gatewayDetails.location);
      const hasFloorPlan =
        currentLocationFloorPlan?.features && currentLocationFloorPlan?.features.length > 0;
      if (hasFloorPlan) {
        setFloorPlan(currentLocationFloorPlan);
      } else {
        fetchLocationFloorplan(gatewayDetails.location)
          .then((floorplan) => setFloorPlan(floorplan))
          .catch((err) => setAlertMsg(err.cause));
      }
    }
  }, [allFloorPlan, gatewayDetails.location]);

  useEffect(() => {
    getFloorPlan();
    const item: PropSensorItemCreate = {
      location: gatewayDetails?.location ?? '',
      name: '',
      shortName: '',
      position: gatewayDetails?.position,
      gateway: gatewayDetails.id,
      timeZone: gatewayDetails?.timeZone,
      type: selectedSubSensor.type,
      fwVersion: '1',
      hwVersion: selectedSubSensor?.hwVersion ?? '',
    };
    setSensorPayload(item);
  }, [getFloorPlan, gatewayDetails, selectedSubSensor]);

  const mapPositionChangeHandler = (posData: SensorPositionData) => {
    const newPosition = { ...position };
    newPosition.lat = posData?.lat ?? 0;
    newPosition.lng = posData?.lng ?? 0;
    newPosition.polygon = posData?.polygon ?? '';
    if (sensorPayload?.type === SubSensorType.OccSignatures) {
      newPosition.azimuth = position?.azimuth ?? 0;
      newPosition.height = position?.height ?? 2.4;
    }
    setAlertMsg('');
    setPosition(newPosition);
  };

  const handleInitialData = () => {
    setFetchingInitialData(true);
    const interval = setInterval(async () => {
      const sensorLatest = await fetchSensorLatest(selectedSubSensor.suggested_id);
      if (sensorLatest?.data && sensorLatest.data.length > 0) {
        clearInterval(interval);
        setFetchingInitialData(false);
      }
    }, 1000);
  };

  const handleSubmit = () => {
    setCreatingSensor(true);
    const item = { ...sensorPayload };
    item.position = position;
    addNewSensor(selectedSubSensor.suggested_id, item as PropSensorItemCreate)
      .then((res) => {
        setCreatingSensor(false);
        handleInitialData();
        // update sensor Details and pass name to app
        dispatch(setSensorsById([res as SensorLatest]));
      })
      .catch((err) => {
        setCreatingSensor(false);
        setAlertMsg(err.cause);
      });
  };

  const textFieldChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    param: string
  ) => {
    const { value } = e.target;
    let item;
    if (param === 'name') {
      const trimmedShortName = value.replace(/ +/g, '').trim().substring(0, 10);
      const capitalizedShortName =
        trimmedShortName.charAt(0).toUpperCase() + trimmedShortName.slice(1);
      item = { ...sensorPayload, name: value, shortName: capitalizedShortName };
    }
    setAlertMsg('');
    setSensorPayload(item as PropSensorItemCreate);
  };

  const nextBtnHandler = () => {
    setAlertMsg('');
    if (steps[activeStep] === InstallSteps.Position) {
      if (!position) setAlertMsg('Sensor must be placed in the floorplan');
      else setActiveStep(activeStep + 1);
    } else if (steps[activeStep] === InstallSteps.Name) {
      if (!sensorPayload?.name) setAlertMsg('Name must be given to sensor');
      else {
        handleSubmit();
        setActiveStep(activeStep + 1);
      }
    }
  };

  const prevBtnHandler = () => {
    setAlertMsg('');
    setActiveStep(activeStep - 1);
  };

  const restartProcess = () => {
    setActiveStep(0);
    setPosition(undefined);
    handleRefresh();
    appSwitchBtnHandler(dispatch);
  };

  return (
    <>
      {alertMsg && <Alert severity="error">{alertMsg}</Alert>}
      {!alertMsg && steps[activeStep] === InstallSteps.Position && (
        <Alert severity="info">Tap to place sensor in the floorplan</Alert>
      )}
      {steps[activeStep] === InstallSteps.Position && (
        <Zoom in={steps[activeStep] === InstallSteps.Position}>
          <Box>
            <PositioningMap
              floorPlan={floorPlan}
              onChange={mapPositionChangeHandler}
              // initial position can be gateway position to zoom in to the place
              position={position}
              mapName="SensorCreate"
              locationState={mapLocationState}
              gatewayPosition={gatewayDetails.position}
            />
          </Box>
        </Zoom>
      )}
      {steps[activeStep] === InstallSteps.Name && (
        <Zoom in={steps[activeStep] === InstallSteps.Name}>
          <div style={{ textAlign: 'center' }}>
            <TextField
              type="text"
              required
              label="Name"
              onChange={(e) => textFieldChange(e, 'name')}
              value={sensorPayload?.name ?? ''}
              variant="standard"
              style={{ width: '80%', margin: '10px', padding: '20px' }}
            />
            <TextField
              type="text"
              required
              label="Gateway Name"
              disabled
              onChange={(e) => textFieldChange(e, 'name')}
              value={getSensorName(allSensorNames, sensorPayload?.gateway)}
              variant="standard"
              style={{ width: '80%', margin: '10px', padding: '20px' }}
            />
          </div>
        </Zoom>
      )}
      {steps[activeStep] === InstallSteps.Confirm ? (
        <Zoom in={steps[activeStep] === InstallSteps.Confirm}>
          <div>
            {creatingSensor && <Loading />}
            <Box sx={{ textAlign: 'center' }}>
              {!alertMsg && !creatingSensor && (
                <>
                  <InstallSuccessIcon
                    sx={{ width: '100%', fontSize: '100px', marginTop: '5rem' }}
                  />
                  <Typography
                    style={{
                      margin: '20px',
                      display: 'flex',
                      justifyContent: 'left',
                    }}
                  >
                    <TaskAltIcon
                      sx={{ fontSize: '24px', marginRight: '10px', color: themeProps.colors.green }}
                    />
                    Sensor has been created successfully
                  </Typography>
                  {fetchingInitialData && (
                    <>
                      <Loading />
                      <Typography style={{ marginTop: '20px' }}>Fetching initial data</Typography>
                    </>
                  )}
                  {!fetchingInitialData && fetchingInitialData !== undefined && (
                    <Typography style={{ margin: '20px', display: 'flex', justifyContent: 'left' }}>
                      <TaskAltIcon
                        sx={{
                          fontSize: '24px',
                          marginRight: '10px',
                          color: themeProps.colors.green,
                        }}
                      />
                      Initial Data received
                    </Typography>
                  )}
                </>
              )}
              <Button
                variant="contained"
                style={{ margin: '20px', textTransform: 'capitalize', width: '80%' }}
                onClick={restartProcess}
              >
                Go to New Sensors List
              </Button>
            </Box>
          </div>
        </Zoom>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={prevBtnHandler}
            startIcon={<KeyboardDoubleArrowLeftIcon />}
            style={{ margin: '0 10px' }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={nextBtnHandler}
            endIcon={<KeyboardDoubleArrowRightIcon />}
            style={{ margin: '0 10px' }}
          >
            {steps[activeStep] === InstallSteps.Name ? 'Create Sensor' : 'Next'}
          </Button>
        </Box>
      )}
    </>
  );
}

export default InstallProcess;
