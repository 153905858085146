import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataTreeItem } from '../../services/api';
import {
  getAvailableLocations,
  getSensorNames,
  getSearchTerm,
  getSortBy,
} from '../../state/selectors';
import { getSensorName } from '../../utils/sensors';
import { getLocationName } from '../../utils/locations';

export default function useDataFiltering(data: DataTreeItem[]): DataTreeItem[] {
  const currentSearchTerm = useSelector(getSearchTerm);
  const [d, setD] = useState<DataTreeItem[]>([]);
  const sensorNames = useSelector(getSensorNames);
  const availableLocations = useSelector(getAvailableLocations);
  const sortBy = useSelector(getSortBy);

  const searchPropF = useMemo(
    () =>
      (function searchInitialiser(): (param: string | number) => string | number {
        const { property } = sortBy;
        switch (property) {
          case 'name':
            return (param: string | number) =>
              getSensorName(sensorNames, param as string).toLowerCase();
          case 'location':
            return (param: string | number) =>
              getLocationName(availableLocations, param as string).toLowerCase();
          case 'time':
          case 'value':
          default:
            return (param: string | number) => param;
        }
      })(),
    [sortBy, availableLocations, sensorNames]
  );

  useEffect(() => {
    const { property, ascending } = sortBy;
    const propToSearch = property === 'name' ? 'id' : property;
    const multiplier = ascending ? 1 : -1;

    setD(
      data
        .filter(({ id, location }: DataTreeItem) => {
          const { term } = currentSearchTerm;
          if (term === '') {
            return true;
          }

          const name = getSensorName(sensorNames, id).toLowerCase();
          if (name.indexOf(term) !== -1) {
            return true;
          }
          const locationName = getLocationName(availableLocations, location).toLowerCase();
          if (locationName.indexOf(term) !== -1) {
            return true;
          }

          return false;
        })
        .sort((a: DataTreeItem, b: DataTreeItem) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const pa = searchPropF(a[propToSearch]);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const pb = searchPropF(b[propToSearch]);

          if (pa < pb) return -multiplier;
          if (pa > pb) return multiplier;
          return 0;
        })
    );
  }, [data, currentSearchTerm, searchPropF, availableLocations, sortBy, sensorNames]);

  return d;
}
