import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Typography from '@mui/material/Typography';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CalendarViewSensorArray from './CalendarViewSensorArray';
import {
  getActiveMarker,
  getAvailableLocations,
  getCurrentLocation,
  getCurrentLocationChildSensors,
  getCurrentLocationFloorplan,
  getHighlightedItem,
  getSelectedEndDate,
  getSelectedSensorIds,
  getSelectedStartDate,
  getSensorNames,
} from '../../state/selectors';
import { DailyMetricItem, SensorLatest, VarName } from '../../services/api';
import {
  spaceProperties,
  FloorplanSpace,
  ValueType,
  HoveredPlot,
  SensorValueItem,
  CalendarSelectionType,
} from './helpers';
import { ActiveItem } from '../../state/types';
import { getSensorName } from '../../utils/sensors';
import { getLocationName } from '../../utils/locations';
import { setSelectedSensors } from '../../state/actions';
import CalendarViewPlot from '../Plots/HeatMap/CalendarViewPlot';
import WeekViewPlot from '../Plots/HeatMap/WeekViewPlot';
import { varNameDetails } from '../../utils/varNames';
import LoaderWithBackdrop from '../HelperComponents/LoaderWithBackdrop';

dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);

interface CalendarViewDataContainerProps {
  sensorSummaryValues: SensorValueItem[];
  locDailyData: DailyMetricItem[];
  hoveredPlot: HoveredPlot | undefined;
  setHoveredPlot: (value: HoveredPlot | undefined) => void;
  selectionType: CalendarSelectionType;
  downloadData: boolean;
  setDownloadData: (value: boolean) => void;
  loading: boolean;
}

function CalendarViewDataContainer({
  sensorSummaryValues,
  locDailyData,
  hoveredPlot,
  setHoveredPlot,
  selectionType,
  downloadData,
  setDownloadData,
  loading,
}: CalendarViewDataContainerProps): JSX.Element {
  const dispatch = useDispatch();
  const floorPlan = useSelector(getCurrentLocationFloorplan);
  const locSensors = useSelector(getCurrentLocationChildSensors);
  const highlightedItem = useSelector(getHighlightedItem);
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const sensorNames = useSelector(getSensorNames);
  const allLocations = useSelector(getAvailableLocations);
  const selectedSensors = useSelector(getSelectedSensorIds);
  const [historyData, setHistoryData] = useState<DailyMetricItem[]>(locDailyData);
  const [floorSensors, setFloorSensors] = useState<SensorLatest[]>([]);
  const [roomSensors, setRoomSensors] = useState<SensorLatest[]>([]);
  const [deskSensors, setDeskSensors] = useState<SensorLatest[]>([]);
  const [areaSensors, setAreaSensors] = useState<SensorLatest[]>([]);
  const [allSensors, setAllSensors] = useState<SensorLatest[]>([]);
  const [hoveredSensor, setHoveredSensor] = useState<ActiveItem>();
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);

  useEffect(() => setHistoryData(locDailyData), [locDailyData]);

  useEffect(() => {
    setHoveredSensor(undefined);
    setHoveredPlot(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, currentLocation]);

  const addedSensors = useMemo(() => {
    // const onlineSensors = filterOnlineSensors(allSensors);
    const activeSensors = selectedSensors.filter((sensor) =>
      allSensors.some((sensorInfo) => sensorInfo.id === sensor)
    );
    return activeSensors;
  }, [allSensors, selectedSensors]);

  useEffect(() => {
    if (highlightedItem.id && highlightedItem.varName)
      setHoveredSensor({ id: highlightedItem.id, varName: highlightedItem.varName });
    else setHoveredSensor(undefined);
  }, [highlightedItem]);

  useEffect(() => {
    const activeMarkerSensors = locSensors.filter((sensor: SensorLatest) =>
      sensor?.data?.some((data) => data?.varName === activeMarker)
    );
    if (activeMarkerSensors) setAllSensors(activeMarkerSensors);
  }, [activeMarker, locSensors]);

  useEffect(() => {
    const allFloorSensors: SensorLatest[] = [];
    const allRoomSensors: SensorLatest[] = [];
    const allDeskSensors: SensorLatest[] = [];
    const allAreaSensors: SensorLatest[] = [];
    allSensors.forEach((sensor: SensorLatest) => {
      const sensorPolygon = sensor?.position?.polygon;
      if (sensorPolygon) {
        const floorPlanFeatures = floorPlan?.features?.find(
          (floorPlanData) =>
            floorPlanData?.id === sensorPolygon ||
            floorPlanData.properties?.id?.toString() === sensorPolygon
        );
        if (floorPlanFeatures) {
          if (floorPlanFeatures.properties.type === spaceProperties[FloorplanSpace.floor].code) {
            allFloorSensors.push(sensor);
          } else if (
            floorPlanFeatures.properties.type === spaceProperties[FloorplanSpace.desk].code
          ) {
            allDeskSensors.push(sensor);
          } else if (
            floorPlanFeatures.properties.type === spaceProperties[FloorplanSpace.room].code
          ) {
            allRoomSensors.push(sensor);
          } else allAreaSensors.push(sensor);
        }
      } else allAreaSensors.push(sensor);
    });
    setFloorSensors(allFloorSensors);
    setRoomSensors(allRoomSensors);
    setDeskSensors(allDeskSensors);
    setAreaSensors(allAreaSensors);
  }, [allSensors, floorPlan?.features]);

  const hasNoSensorData =
    deskSensors.length === 0 &&
    floorSensors.length === 0 &&
    roomSensors.length === 0 &&
    areaSensors.length === 0;

  const heatMapSensorsList = useMemo(() => {
    let sensors: string[] = [];
    if (addedSensors.length > 0) sensors = addedSensors;
    else if (hoveredSensor) sensors = [hoveredSensor.id];
    return sensors;
  }, [hoveredSensor, addedSensors]);

  useEffect(() => {
    if (heatMapSensorsList.length > 0) {
      setHistoryData(locDailyData.filter((data) => heatMapSensorsList.includes(data.id)));
    } else setHistoryData(locDailyData);
  }, [heatMapSensorsList, locDailyData]);

  const dayNum = dayjs(hoveredPlot?.y).get('day');
  const dateFormatY = dayjs(hoveredPlot?.y).format('dddd');
  const dateFormatX = dayjs(hoveredPlot?.x).day(dayNum).format('MMM DD YYYY');
  const dayFormat = dateFormatX !== 'Invalid Date' ? dateFormatX : hoveredPlot?.x;
  const dateLabelY = dateFormatY !== 'Invalid Date' ? dateFormatY : hoveredPlot?.y;
  const dateLabelX =
    selectionType === CalendarSelectionType.week
      ? `${hoveredPlot?.x} ${typeof hoveredPlot?.x === 'number' ? `o'clock` : ''}`
      : dayFormat;

  const sourceLabel = useMemo(() => {
    let label = getLocationName(allLocations, currentLocation);
    if (heatMapSensorsList.length > 0) {
      if (heatMapSensorsList.length === 1)
        label = getSensorName(sensorNames, heatMapSensorsList[0]);
      else label = `${heatMapSensorsList.length} sensors`;
    }
    return label;
  }, [allLocations, currentLocation, heatMapSensorsList, sensorNames]);

  const getHeatmapSubHeading = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const plotLabel = parseFloat(hoveredPlot?.z).toFixed(1);
    const metric = varNameDetails[activeMarker].metric ?? '';
    const heading = hoveredPlot ? `${sourceLabel} (${plotLabel}${metric})` : sourceLabel;
    return heading;
  };

  const clearSensorHandler = () => {
    const clearedSensors = selectedSensors.filter(
      (sensor) => !allSensors.some((sensorInfo) => sensorInfo.id === sensor)
    );
    dispatch(setSelectedSensors(clearedSensors));
  };

  const spaces = [
    { name: spaceProperties[FloorplanSpace.desk].label, sensors: deskSensors },
    { name: spaceProperties[FloorplanSpace.room].label, sensors: roomSensors },
    { name: spaceProperties[FloorplanSpace.floor].label, sensors: floorSensors },
    { name: spaceProperties[FloorplanSpace.area].label, sensors: areaSensors },
  ];

  const valueType = activeMarker === VarName.MotionEvent ? ValueType.utl : ValueType.avg;

  const defaultLabel = useMemo(() => {
    const hasTotValue =
      locDailyData.filter((data) => Object.prototype.hasOwnProperty.call(data, 'tot')).length > 0;

    const titleText = `${hasTotValue ? 'Total' : 'Average'} values ${dayjs(startDate).format(
      'MMM DD'
    )} - ${dayjs(endDate).format('MMM DD YYYY')}`;

    return titleText;
  }, [locDailyData, startDate, endDate]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 0 10px 10px',
        }}
      >
        <Typography
          variant="h6"
          style={{ marginTop: '10px' }}
        >{`Selected for ${getHeatmapSubHeading()}`}</Typography>
        {addedSensors.length > 0 && (
          <IconButton
            onClick={() => clearSensorHandler()}
            style={{ padding: '0', marginLeft: '10px' }}
          >
            <Tooltip title="Remove all locked sensors">
              <RemoveCircleIcon color="error" />
            </Tooltip>
          </IconButton>
        )}
      </div>
      <div>
        <div style={{ position: 'relative' }}>
          {loading && <LoaderWithBackdrop />}
          {selectionType === CalendarSelectionType.week ? (
            <WeekViewPlot
              historyData={historyData}
              varName={activeMarker}
              valueType={valueType}
              setHoveredPlot={setHoveredPlot}
              downloadData={downloadData}
              setDownloadData={setDownloadData}
              sourceLabel={sourceLabel.replaceAll(' ', '-')}
            />
          ) : (
            <CalendarViewPlot
              historyData={historyData}
              varName={activeMarker}
              valueType={valueType}
              setHoveredPlot={setHoveredPlot}
              downloadData={downloadData}
              setDownloadData={setDownloadData}
              sourceLabel={sourceLabel.replaceAll(' ', '-')}
            />
          )}
        </div>
        <Typography variant="h6" style={{ margin: '10px' }}>
          {hoveredPlot ? `${dateLabelY} - ${dateLabelX}` : defaultLabel}
        </Typography>

        {hasNoSensorData && (
          <Alert severity="error" style={{ marginTop: '1rem' }}>
            No sensor data found
          </Alert>
        )}
        {spaces.map((space) => (
          <div key={space.name}>
            {space.sensors.length > 0 && (
              <div>
                <CalendarViewSensorArray
                  sensors={space.sensors}
                  title={space.name}
                  sensorSummaryValues={sensorSummaryValues}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default CalendarViewDataContainer;
