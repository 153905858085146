import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSelector, useDispatch } from 'react-redux';

import { getSensorNames, getSensorsById, getTempUser } from '../state/selectors';
import Loading from '../components/Loading';
import { handleLogout } from '../Shell/helpers';
import { setTempUser } from '../state/actions';
import { fetchSensorLatest } from '../services/apiService';

function AppAdminSensor(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const { sensorId } = useParams() as { sensorId: string };
  const tempUser = useSelector(getTempUser);
  const allSensorNames = useSelector(getSensorNames);
  const sensorDetail = useSelector(getSensorsById).get(sensorId);

  // eslint-disable-next-line consistent-return
  const renderComponent = () => {
    // checking with allSensorsNames size to make sure store already has some data
    if (allSensorNames.size > 0) {
      if (sensorDetail) navigate(`/sensors/${sensorId}`);
      else {
        fetchSensorLatest(sensorId)
          .then((details) => {
            if (details) navigate(`/sensors/${sensorId}`);
          })
          .catch((err) => {
            const resStatus = err?.cause?.status ?? undefined;
            if (resStatus === 404) navigate(`/install/${sensorId}`);
          });
      }
    }
  };

  // this page only render component for authenticated users, if only tempuser is found redirect user with login process
  useEffect(() => {
    if (!user) {
      dispatch(setTempUser(null));
      handleLogout(tempUser, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user && (
        <>
          {allSensorNames.size === 0 && <Loading />}
          {renderComponent()}
        </>
      )}
    </>
  );
}

export default AppAdminSensor;
