/* eslint-disable import/prefer-default-export */

import { Position, SensorLatest, VarName } from '../services/api';
import { fetchSensorLatest } from '../services/apiService';
import { NearBySensor } from '../state/types';

export function getSensorName(
  sensorNames: Map<string, string> | undefined,
  sensorId: string | undefined
): string {
  if (!sensorId) {
    return 'UNKNOWN';
  }

  if (!sensorNames) {
    return sensorId;
  }

  return sensorNames.get(sensorId) ?? sensorId;
}

export const getSensorDetailsById = async (
  id: string,
  allSensorNames: Map<string, string>,
  allSensorDetails: Map<string, SensorLatest>
) => {
  let targetSensor: SensorLatest | undefined;
  const searchSensorDetail = Array.from(allSensorDetails.values()).find((sensor) =>
    sensor.id.match(new RegExp(id, 'g'))
  );
  if (searchSensorDetail) targetSensor = searchSensorDetail;
  else {
    const urlSensorId = Array.from(allSensorNames.keys()).find((sensor) =>
      sensor.match(new RegExp(id, 'g'))
    );
    if (urlSensorId) {
      // return undefined for error during fetching sensor details
      targetSensor = await fetchSensorLatest(urlSensorId).catch(() => undefined);
    }
  }
  return targetSensor;
};

const findPositionBySignalAndMostRecent = async (
  bleSensors: NearBySensor[] | undefined,
  sensorsInLocation: SensorLatest[] | undefined,
  appScanStatus: boolean
) => {
  let targetPosition: Position | undefined;
  let targetPolygon;
  const sum = { x: 0, y: 0, m: 0 };
  if (bleSensors && appScanStatus) {
    const strongestRssi = bleSensors[0].rssi;
    const mostRecent = bleSensors.sort((a, b) => b.lastSeen - a.lastSeen)[0].lastSeen;
    for (let i = 0; i < bleSensors.length; i++) {
      const { id, rssi, lastSeen } = bleSensors[i];
      const sensorPosition = sensorsInLocation?.find((sensor) => sensor.id === id)?.position;
      if (!targetPolygon) targetPolygon = sensorPosition?.polygon;
      if (sensorPosition) {
        const { lat, lng } = sensorPosition;
        if (lat && lng) {
          const signalStrength = 10 ** ((rssi - strongestRssi) / (10 * 2));
          const timeDiff = mostRecent - lastSeen;
          let lastSeenStrength;
          if (timeDiff < 10) lastSeenStrength = 1;
          else if (timeDiff < 60) lastSeenStrength = 0.5;
          else lastSeenStrength = 0.25;
          sum.x += (signalStrength + lastSeenStrength) * lat;
          sum.y += (signalStrength + lastSeenStrength) * lng;
          sum.m += signalStrength + lastSeenStrength;
        }
      }
    }
  }
  if (sum.x && sum.y && sum.m)
    targetPosition = { lat: sum.x / sum.m, lng: sum.y / sum.m, polygon: targetPolygon };
  return targetPosition ?? null;
};

export const getBleSensorTargetPosition = async (
  bleSensors: NearBySensor[] | undefined,
  sensorsInLocation: SensorLatest[] | undefined,
  appScanStatus: boolean
) => findPositionBySignalAndMostRecent(bleSensors, sensorsInLocation, appScanStatus);

// might be useful in future
export const findBleSensorsLocation = async (
  sensorsList: NearBySensor[] | undefined,
  allSensorNames: Map<string, string>,
  allSensorDetails: Map<string, SensorLatest>
) => {
  let validSensor: SensorLatest | undefined;
  if (sensorsList) {
    for (let i = 0; i < sensorsList.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      const sensorInfo = await getSensorDetailsById(
        sensorsList[i].id,
        allSensorNames,
        allSensorDetails
      ).catch(() => undefined);
      const sensorStatus = sensorInfo?.data?.find(
        (dataItem) => dataItem.varName === VarName.OnlineStatus
      );
      if (sensorInfo && sensorStatus && sensorStatus.value === 1) {
        validSensor = sensorInfo;
        break;
      }
    }
  }
  return validSensor;
};
