import { createSelector } from '@reduxjs/toolkit';
import {
  DataTreeItem,
  GeoJSON as GeoJSONApiType,
  PropLocationItem,
  SensorLatest,
  LocationType,
  Position,
  UserPublic,
} from '../services/api';
import { UserAdminTypes } from '../services/authenticationService';
import {
  LocationTreeNode,
  RootState,
  UserAuthState,
  ActiveItem,
  SortType,
  SearchPayload,
  HoursSelection,
  TempUser,
  NearBySensor,
  ThemeMode,
  GhostParams,
  SelectedBand,
  LocSensorStatusTree,
} from './types';
import { isDataExpired } from '../utils/functions';
import { VarName } from '../utils/varNames';

const getUserAuthData = (store: RootState): UserAuthState => store.userAuth;

export const getTempUser = (store: RootState): TempUser | null => getUserAuthData(store)?.tempUser;
export const getUserDetails = (store: RootState): UserPublic | null =>
  getUserAuthData(store)?.userDetails;
export const getUserIsAdmin = (store: RootState): boolean =>
  Object.values<string>(UserAdminTypes).includes(
    getUserAuthData(store)?.userDetails?.access_level ?? 'readOnly'
  );

// get user position based on selected marker and ble sensors (if from mobile app)
export const getUserPosition = (store: RootState): Position | null => store.userAuth.userPosition;

// boolean to calculate bleSensors location and user position
export const getBleLocSwitchStatus = (store: RootState): boolean =>
  store.uiSettings.allowBleLocSwitch;

// store sensors info receive from mobile app
export const getBleSensors = (store: RootState): NearBySensor[] | undefined =>
  store.sensors.bleSensors;

// to redirect to pages received from app. Here base URL is always same and navigation is handled from received message bleWebViewUrl
export const getBleWebViewUrl = (store: RootState): string => store.uiSettings.bleWebViewUrl;

export const getAvailableLocations = (store: RootState): Map<string, LocationTreeNode> =>
  store.locations.locations;

export const getLocationsById = (store: RootState): Map<string, PropLocationItem> =>
  store.locations.locationsById;

export const getLocationIdsByParentId = (store: RootState): Map<string, string[]> =>
  store.locations.locationsByParent;

export const getCurrentLocation = (store: RootState): string => store.locations.currentLocation;

export const getCurrentLocationProps = createSelector(
  [getCurrentLocation, getAvailableLocations],
  (currentLocation, locations) => {
    const path = currentLocation.slice(1).split('#');

    let node = locations.get(path[0]);
    for (let i = 1; i < path.length; i++) {
      if (node?.id === currentLocation) {
        break;
      }
      node = node?.children?.get(path[i]);
    }

    // The last element won't get checked by the loop above
    if (node?.id === currentLocation) {
      return node;
    }

    return undefined;
  }
);

export const getCurrentBuildingProps = (store: RootState): PropLocationItem | undefined => {
  const { currentLocation, locations } = store.locations;
  const path = currentLocation.slice(1).split('#');

  let node = locations.get(path[0]);
  for (let i = 1; i < path.length; i++) {
    if (node?.raw.type === LocationType.Building) {
      break;
    }
    node = node?.children?.get(path[i]);
  }

  // The last element won't get checked by the loop above
  if (node?.raw.type === LocationType.Building) {
    return node.raw;
  }

  return undefined;
};

export const getSensorDetails = (store: RootState): Map<string, SensorLatest> =>
  store.sensors.sensorsById;

export const getSensorNames = createSelector([getSensorDetails], (sensorDetails) => {
  const sensorNames = new Map<string, string>();
  const allSensors = Array.from(sensorDetails.values());
  allSensors.forEach(({ id, name }) => {
    sensorNames.set(id, name ?? '');
  });
  return sensorNames;
});

const getSelectedVarsRaw = (store: RootState): Array<VarName> => store.sensors.selectedVars;

export const getSelectedVars = createSelector([getSelectedVarsRaw], (selectedVars) => selectedVars);

export const getSelectedSensorIds = (store: RootState): string[] => store.sensors.selectedSensorIds;

export const getActiveMarker = (store: RootState): VarName => store.uiSettings.activeMarker;

export const getSensorsByVarName = (store: RootState): Map<VarName, string[]> =>
  store.sensors.sensorsByVarName;

export const getSensorsById = (store: RootState): Map<string, SensorLatest> =>
  store.sensors.sensorsById;

export const getSensorsByLocId = (store: RootState): Map<string, string[]> =>
  store.sensors.sensorsByLocationId;

// get online and offline sensor data for selected sensor
export const getLocSensorStatusData = createSelector(
  [getSelectedVars, getSensorsById, getCurrentLocation, getSensorsByVarName],
  (selectedVars, sensorsById, currentLocation, sensorsByVarName): LocSensorStatusTree => {
    const onlineData = new Map<VarName, DataTreeItem[]>();
    const offlineData = new Map<VarName, DataTreeItem[]>();

    const filteredSensors = Array.from(sensorsById.values()).filter(
      (item) =>
        currentLocation === '#' ||
        item.location === currentLocation ||
        item.location?.startsWith(`${currentLocation}#`)
    );

    selectedVars.forEach((varName) => {
      const varNameIds = sensorsByVarName.get(varName) || [];
      // filter sensorIds for location from all Ids
      const locFilteredIds = varNameIds.filter((id) =>
        filteredSensors.some((sensor) => sensor.id === id)
      );

      const allValuesMap = new Map();
      // get dataItem for each id finding for sensor details
      locFilteredIds.forEach((id) => {
        const sensorDetails = filteredSensors.find((sensor) => sensor.id === id);
        const dataItem = sensorDetails?.data?.find(
          (item) => item.varName === varName
        ) as DataTreeItem;
        allValuesMap.set(id, { ...dataItem, id, location: sensorDetails?.location });
      });

      const allDataTreeItem: DataTreeItem[] = Array.from(allValuesMap.values());

      let onlineValues: DataTreeItem[] = [];
      let offlineValues: DataTreeItem[] = [];
      if (varName === VarName.OnlineStatus) onlineValues = allDataTreeItem;
      else {
        const filteredOnlineItem: DataTreeItem[] = [];
        const filteredOfflineItem: DataTreeItem[] = [];
        allDataTreeItem.forEach((item) => {
          const sensorStatusTree = sensorsById
            .get(item.id)
            ?.data?.find((el) => el.varName === VarName.OnlineStatus);
          if (sensorStatusTree) {
            const { value } = sensorStatusTree;
            // filter from sensor's online status value, and varname time stamp expiration
            if (value !== undefined && (value > 0 || !isDataExpired(item.time)))
              filteredOnlineItem.push(item);
            // avoid retired sensors as offline sensors
            else if (value !== undefined && value > -2) filteredOfflineItem.push(item);
          }
        });

        onlineValues = filteredOnlineItem;
        offlineValues = filteredOfflineItem;
      }

      onlineData.set(varName, onlineValues);
      offlineData.set(varName, offlineValues);
    });
    return { onlineData, offlineData };
  }
);

export const getSelectedLocationVarValues = createSelector(
  [getSelectedVars, getSensorsById, getCurrentLocation, getSensorsByVarName],
  (selectedVars, sensorsById, currentLocation, sensorsByVarName): Map<VarName, DataTreeItem[]> => {
    const data = new Map<VarName, DataTreeItem[]>();
    // filter sensors based on desired location
    const filteredSensors = Array.from(sensorsById.values()).filter(
      (item) =>
        currentLocation === '#' ||
        item.location === currentLocation ||
        item.location?.startsWith(`${currentLocation}#`)
    );

    // create record for each varName
    selectedVars.forEach((varName) => {
      const varNameIds = sensorsByVarName.get(varName) || [];
      // filter sensorIds for location from all Ids
      const locFilteredIds = varNameIds.filter((id) =>
        filteredSensors.some((sensor) => sensor.id === id)
      );

      const allValuesMap = new Map();
      // get dataItem for each id finding for sensor details
      locFilteredIds.forEach((id) => {
        const sensorDetails = filteredSensors.find((sensor) => sensor.id === id);
        const dataItem = sensorDetails?.data?.find(
          (item) => item.varName === varName
        ) as DataTreeItem;
        allValuesMap.set(id, { ...dataItem, id, location: sensorDetails?.location });
      });

      // Filter to restrict to sub-tree of current location
      let filteredValues = Array.from(allValuesMap.values());
      // Filter to remove expired data
      // Don't expire onlineStatus or battery level
      if (
        varName !== VarName.OnlineStatus &&
        varName !== VarName.BatteryLevelPct &&
        varName !== VarName.MotionEvent
      ) {
        // Filter expired data
        filteredValues = filteredValues.filter((item) => !isDataExpired(item.time));
      }
      data.set(varName, filteredValues);
    });
    return data;
  }
);

export const getSubLocationVarValues = createSelector(
  [getSelectedVars, getSensorsById, getCurrentLocation, getSensorsByVarName],
  (selectedVars, sensorsById, currentLocation, sensorsByVarName) => {
    const data = new Map<VarName, DataTreeItem[]>();
    // filter sensors based on desired location
    const filteredSensors = Array.from(sensorsById.values())
      .filter(
        (item) =>
          currentLocation === '#' ? true : item.location?.startsWith(`${currentLocation}#`) // we can't just compare starting with current location as some location might have similar naming as #BLD-R, #BLD-R2
      )
      .slice(0, 200); // to display sensors in root location as well, it will have some limitation to us devs as we have access to all location

    selectedVars.forEach((varName) => {
      const varNameIds = sensorsByVarName.get(varName) || [];

      // filter sensorIds for location from all Ids
      const locFilteredIds = varNameIds.filter((id) =>
        filteredSensors.some((sensor) => sensor.id === id)
      );

      const allValuesMap = new Map();

      // get dataItem for each id finding for sensor details
      locFilteredIds.forEach((id) => {
        const sensorDetails = filteredSensors.find((sensor) => sensor.id === id);
        const dataItem = sensorDetails?.data?.find(
          (item) => item.varName === varName
        ) as DataTreeItem;
        allValuesMap.set(id, { ...dataItem, id, location: sensorDetails?.location });
      });

      data.set(varName, Array.from(allValuesMap.values()));
    });
    return data;
  }
);

export const getCurrentLocationChildLocations = (store: RootState): PropLocationItem[] => {
  const children = store.locations.currentLocationChildren || {};
  return children;
};

export const getCurrentLocationChildSensors = createSelector(
  [getCurrentLocation, getSensorsByLocId, getSensorsById],
  (currentLocation, locSensorRecord, allSensors) => {
    const locSensors = locSensorRecord.get(currentLocation);
    const locSensorDetails: SensorLatest[] = [];
    locSensors?.forEach((sensor) => {
      const sensorDetails = allSensors.get(sensor);
      if (sensorDetails) locSensorDetails.push(sensorDetails);
    });
    return locSensorDetails;
  }
);

export const getCurrentLocationFloorplan = (store: RootState): GeoJSONApiType | undefined => {
  const currentLocation = getCurrentLocation(store);
  const floorplan = store.locations.floorplans.get(currentLocation);
  return floorplan;
};

export const getAllLocationFloorplan = (store: RootState): Map<string, GeoJSONApiType> => {
  const allFloorplan = store.locations.floorplans;
  return allFloorplan;
};

export const getSelectedStartDate = (store: RootState): Date => store.sensors.selectedStartDate;

export const getSelectedEndDate = (store: RootState): Date => store.sensors.selectedEndDate;

export const getMapSize = (store: RootState): 'collapsed' | 'normal' | 'full' =>
  store.uiSettings.mapSize;

export const getHighlightedItem = (store: RootState): ActiveItem =>
  store.uiSettings.highlightedItem;

export const getClickedItem = (store: RootState): ActiveItem => store.uiSettings.clickedItem;

export const getDashboardPanels = (store: RootState): VarName[] => store.uiSettings.dashboardPanels;

export const getActivePlotVars = (store: RootState): VarName[] => store.uiSettings.activePlotVars;

export const getShowHelp = (store: RootState): boolean => store.uiSettings.showHelp;

export const getSearchTerm = (store: RootState): SearchPayload => store.uiSettings.searchTerm;

export const getSortBy = (store: RootState): SortType => store.uiSettings.sortBy;

export const getSelectedHours = (store: RootState): HoursSelection =>
  store.uiSettings.selectedHours;

export const getMotionThreshold = (store: RootState): [number, number] =>
  store.uiSettings.motionThreshold;

export const getShowFloorlanLabels = (store: RootState): boolean =>
  store.uiSettings.showFloorplanLabels;

export const getShowSensorLabels = (store: RootState): boolean => store.uiSettings.showSensorLabels;
export const getShowNavDrawer = (store: RootState): boolean => store.uiSettings.showNavDrawer;
export const getThemeMode = (store: RootState): ThemeMode => store.uiSettings.themeMode;
export const getGhostParams = (store: RootState): GhostParams => store.uiSettings.ghostParams;
export const getShowStackedTraces = (store: RootState): boolean =>
  store.uiSettings.showStackedTraces;

export const getSelectedBand = (store: RootState): SelectedBand | null =>
  store.uiSettings.selectedBand;

export const getVarnameRefreshTamp = (store: RootState): Map<VarName, number> =>
  store.sensors.refreshTimeStamp;

export const getShowAdvancedAnalysis = (store: RootState): boolean =>
  store.uiSettings.showAdvancedAnalysis;
