import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RoomIcon from '@mui/icons-material/Room';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ColouredLinePlot from '../Plots/ColouredLinePlot';
import { DataTreeItem } from '../../services/api';
import {
  getActiveMarker,
  getAvailableLocations,
  getClickedItem,
  getHighlightedItem,
  getMotionThreshold,
  getSelectedSensorIds,
  getSensorNames,
} from '../../state/selectors';
import DataStore from '../../services/dataStore';
import { SensorHistoryPlotItem } from '../Plots/plotCommon';
import { getDataBandParams } from '../../utils/dataBandParams';
import { getSensorName } from '../../utils/sensors';
import { getLocationName } from '../../utils/locations';
import SensorIcon from '../../styles/icons/SensorIcon';
import { StorageTypes, persistState } from '../../utils/persistentState';
import { setClickedItem, setCurrentLocation, setSelectedSensors } from '../../state/actions';
import DataValueString, { getDataValueNumber } from '../HelperComponents/DataValueString';
import { getShortTimeStr } from '../../utils/functions';
import { themeProps } from '../../styles/theme';

interface SensorItemProps {
  sensorData: DataTreeItem;
  isOnline: boolean;
}

function SensorItem({ sensorData, isOnline }: SensorItemProps): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataStore = DataStore.getInstance();
  const allSensorNames = useSelector(getSensorNames);
  const activeMarker = useSelector(getActiveMarker);
  const allLocations = useSelector(getAvailableLocations);
  const selectedSensorIds = useSelector(getSelectedSensorIds);
  const clickedItem = useSelector(getClickedItem);
  const highlightedItem = useSelector(getHighlightedItem);
  const rangeValue = useSelector(getMotionThreshold);
  const [sensorPlotData, setSensorPlotData] = useState<SensorHistoryPlotItem>();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const { id, varName, location } = sensorData;

  const onMenuClick = (navUrl: string) => {
    setMenuAnchor(null);
    navigate(navUrl);
  };

  useEffect(() => {
    dataStore.getHistory(id, varName).then((history) => {
      const plotDataItem: SensorHistoryPlotItem = {
        varName: activeMarker,
        sensorId: id,
        sensorName: dataStore.getDetails(id)?.name ?? '',
        history: {
          time: history.map(({ time }) => time),
          value: history.map(({ value }) => value),
        },
      };
      setSensorPlotData(plotDataItem);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, dataStore, sensorData, rangeValue]);

  // add/remove sensors for compare page
  const toggleCheckBox = () => {
    const currentIndex = selectedSensorIds.indexOf(id);
    const newSelectedSensorIds = [...selectedSensorIds];

    if (currentIndex === -1) {
      newSelectedSensorIds.push(id);
    } else {
      newSelectedSensorIds.splice(currentIndex, 1);
    }

    persistState(newSelectedSensorIds, StorageTypes.SelectedPlotSensors);
    dispatch(setSelectedSensors(newSelectedSensorIds));
  };

  const { color } = getDataBandParams(varName, getDataValueNumber(sensorData, rangeValue) ?? 0);
  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;

  const goToLocation = (locationId: string) => {
    setMenuAnchor(null);
    dispatch(setCurrentLocation(locationId));
  };

  return (
    <ListItemButton
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor:
          id === activeItem.id
            ? `${isOnline ? color : themeProps.colors.white}66`
            : theme.palette.primary.light,
        borderRadius: '8px',
        padding: '5px',
      }}
      onClick={() => dispatch(setClickedItem({ id, varName: activeMarker }))}
    >
      <Grid container spacing={1} style={{ padding: '0 5px' }}>
        <Grid item sm={2} xs={4} style={{ alignSelf: 'center' }}>
          <div style={{ display: 'flex' }}>
            <FiberManualRecordIcon
              style={{
                width: '12px',
                height: '12px',
                alignSelf: 'center',
                color: isOnline ? color : themeProps.colors.lightGrey,
              }}
            />
            <Typography variant="h5" style={{ alignSelf: 'center', marginLeft: '10px' }}>
              {sensorData.value !== undefined && (
                <DataValueString
                  value={sensorData.value}
                  varName={activeMarker}
                  time={sensorData.time}
                />
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={1} xs={2} style={{ alignSelf: 'center' }}>
          <span>{sensorData.time && getShortTimeStr(sensorData.time)}</span>
        </Grid>

        <Grid item sm={3} xs={5} style={{ alignSelf: 'center' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="body1" style={{ marginLeft: '10px', alignSelf: 'center' }}>
              {`${getSensorName(allSensorNames, id)} - ${getLocationName(allLocations, location)}`}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={4} xs={9}>
          {sensorPlotData && (
            <Box>
              <ColouredLinePlot data={sensorPlotData} inlinePlot transparentBg staticPlot />
            </Box>
          )}
        </Grid>
        <Grid item sm={1} xs={2} style={{ textAlign: 'center' }}>
          <Checkbox checked={selectedSensorIds.indexOf(id) !== -1} onChange={toggleCheckBox} />
        </Grid>
        <Grid item sm={1} xs={1} style={{ textAlign: 'end', alignSelf: 'center' }}>
          <IconButton
            onClick={(event) => setMenuAnchor(event.currentTarget)}
            sx={{ background: 'transparent' }}
          >
            <MoreHorizIcon />
          </IconButton>
        </Grid>
        <Menu
          id="settings-menu"
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuList sx={{ padding: 0 }}>
            <MenuItem onClick={() => onMenuClick(`/sensors/${id}`)}>
              <SensorIcon />
              <span style={{ marginLeft: '5px' }}>Sensor Info</span>
            </MenuItem>
            <MenuItem onClick={() => goToLocation(location ?? '#')}>
              <RoomIcon />
              <span style={{ marginLeft: '5px' }}>Go to Location</span>
            </MenuItem>
            <MenuItem
              onClick={() => onMenuClick(`/location/${encodeURIComponent(location ?? '#')}`)}
            >
              <RoomIcon />
              <span style={{ marginLeft: '5px' }}>Location Info</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </Grid>
    </ListItemButton>
  );
}

export default SensorItem;
