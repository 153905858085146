import React from 'react';
import posthog from 'posthog-js';
import { Dispatch } from 'redux';

import { Auth, I18n } from 'aws-amplify';
import { Heading, useTheme } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { Buffer } from 'buffer';
import { TempUser } from '../state/types';
import {
  clearReduxStore,
  setBleWebViewUrl,
  setShowNavDrawer,
  setTempUser,
  setUserPublic,
} from '../state/actions';
import { StorageTypes, clearState } from '../utils/persistentState';
import { ActionTypes } from '../state/actionTypes';

dayjs.extend(calendar);

export const appSwitchBtnHandler = (dispatch: Dispatch<ActionTypes>) => {
  dispatch(setShowNavDrawer(false));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(JSON.stringify({ screen: 'home' }));
  dispatch(setBleWebViewUrl('')); // For special use case if sensor details are changed in app sensor info
};

export const handleLogout = (tempUser: TempUser | null, dispatch: Dispatch<ActionTypes>) => {
  if (tempUser) {
    dispatch(setTempUser(null));
  } else {
    Auth.signOut();
    dispatch(setUserPublic(null));
  }
  // Clear other areas of store that might be problematic
  // TODO Should really remove all sensor/location data.
  clearState(StorageTypes.CurrentLocation);
  clearState(StorageTypes.SelectedPlotSensors);
  dispatch(clearReduxStore());
  posthog.reset();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(JSON.stringify({ hasUser: false }));
};

export const tempUserExpiryStr = (expTime: number): string => {
  const expStr = dayjs.unix(expTime).calendar(null, {
    sameDay: '[today at] HH:mm', // The same day ( today at 14:30 )
    nextDay: '[tomorrow]', // The next day ( tomorrow )
    nextWeek: 'dddd', // The next week ( Sunday )
    lastDay: '[yesterday]', // The day before ( yesterday )
    lastWeek: '[last] dddd', // Last week ( last Monday )
    sameElse: 'DD/MM/YYYY', // Everything else ( 7/10/2011 )
  });
  return `Temporary (Access expires ${expStr})`;
};

export const loginComponents = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to the LightFi portal
        </Heading>
      );
    },
  },
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  return JSON.parse(Buffer.from(base64Url, 'base64').toString('utf8'));
};

I18n.putVocabulariesForLanguage('en', {
  'Reset your password': 'Reset your LightFi password',
  'Forgot your password?': 'Reset Password',
});

export const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: 'Enter your email address',
      isRequired: true,
      label: 'Email Address *',
    },
    password: {
      labelHidden: false,
      placeholder: 'Enter Your password',
      isRequired: true,
      label: 'Password *',
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'Enter your email address',
      isRequired: true,
      label: 'Email Address *',
    },
  },
};
